import * as React from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import {
	Field, FieldProps, Form, Formik, FormikProps,
} from 'formik';
import * as Yup from 'yup';

import Button from '@common/react/components/Forms/Button';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { emailValidator, phoneRequiredValidator } from '@common/react/utils/validationHelpers';
import { FormikPhoneControl } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import useRequest from '@common/react/hooks/useRequest';
import LazyDatePickerReact from '@common/react/components/base/DatePicker/LazyDatepicker';

import { useMobileView } from '@commonTuna/react/utils/useMobileView';
import { Device } from '@commonTuna/react/objects/Device';
import { Page, PageType } from '@commonTuna/react/objects/Page';
import { Gender } from '@commonTuna/react/objects/Enums';
import { Doctor } from '@commonTuna/react/objects/BaseDoctor';
import { Inquiry } from '@commonTuna/react/objects/Inquiry';

import { TranslatedErrorMessage } from '@app/components/UI/TranslatedErrorMessage/TranslatedErrorMessage';
import { getLangByName, getPageShortName } from '@app/components/Utils';
import { ApplicationState } from '@app/store';
import TranslatedFormikField from '@app/components/Forms/TranslatedFormikField/TranslatedFormikField';
import DoctorSelect from '@app/components/UI/DoctorSelect/DoctorSelect';
import LocationSelect from '@app/components/UI/LocationSelect/LocationSelect';
import { useCompanySettingsContext } from '@app/components/UI/CompanySettingsProvider';
import Select from '@app/components/UI/BseSelect';

const year = new Date().getFullYear();

const years = Array.from({ length: 83 }).map((_, i) => year - 100 + i);

interface ValuesInquiry extends Inquiry {
	timeInterval: number | null;
}

type FormValues = Omit<ValuesInquiry, 'checked' | 'deleted' | 'id' | 'name' | 'status' | 'podiumReviewRequested'>;

export interface ContactsUsFormProps extends WithTranslation {
	className?: string;
	onSave?: () => void;
	doctor?: Doctor;
	pageId?: number;
	locationId?: number;
	controlsIdPrefix?: string;
	atModal?: boolean;
}

const lengthValidator = (maxLength) => Yup.string().max(maxLength, { key: 'Must be less than character', value: maxLength });

const lengthRequiredValidator = (maxLength) => Yup.string().max(maxLength, { key: 'Must be less than character', value: maxLength }).required();

const validationSchema = Yup.object().shape({
	firstName: lengthRequiredValidator(20),
	lastName: lengthRequiredValidator(20),
	email: emailValidator,
	phone: phoneRequiredValidator,
	text: lengthValidator(200),
});

interface ContactsUsFormState {
	isLoading: boolean;
	success: 0 | 1 | 2;
	error: null | string;
	loaders: {
		times: boolean;
	};
}

type Props = ContactsUsFormProps;

const getMainPageId = (mainPageMenu: Array<Page>, id) => {
	const findId = (mainPage: Page, id: number) => {
		if (mainPage?.id === id) return id;
		return mainPage?.children?.list.length
			? mainPage.children.list
				.map((page) => (findId(page, id) > 0 ? id : -1))
				.find((id) => id > 0)
			: -1;
	};

	return mainPageMenu?.filter((q) => q.pageType === PageType.SERVICE)
		.find((page) => findId(page, id) > 0)?.id || -1;
};

const ContactUsForm: React.FC<Props> = (props) => {
	const {
		offices, language, menu, initDoctors,
	} = useSelector((state: ApplicationState) => ({
		menu: state.menu,
		language: state.login.lang,
		initDoctors: state.initDoctors,
		offices: state.offices,
	}), shallowEqual);
	const timer = React.useRef<any>(null);
	const request = useRequest();

	const isMobile = useMobileView();

	const { i18n } = useTranslation();
	const ref = React.useRef(false);
	const container = React.useRef<HTMLDivElement>(null);

	const location = useLocation();

	const { companySettings: { showDateOfBirth } } = useCompanySettingsContext();

	const [state, setState] = React.useState<ContactsUsFormState>({
		isLoading: false,
		error: null,
		success: 0,
		loaders: {
			times: false,
		},
	});

	const mainPageMenu = menu.items.filter((q) => q.pageType === PageType.SERVICE);

	const getValueForSelect = (value: number) => {
		return value > 0 ? value : undefined;
	};

	const onSubmit = (values: FormValues, { resetForm }) => {
		setState((state) => ({ ...state, isLoading: true }));

		const lang = getLangByName(i18n.language);

		/* this.setTimeToDate(values); */

		const newValues = {
			...values,
			locationId: (values.locationId as number) > 0 ? values.locationId : null,
			pageId: (values.pageId as number) > 0 ? values.pageId : null,
			mainPageId: (values.mainPageId as number) > 0 ? values.mainPageId : null,
			doctorId: (values.doctorId as number) > 0 ? values.doctorId : null,
			language: lang,
			device: isMobile ? Device.Mobile : Device.Desktop,
			path: location.pathname,
		};

		request('inquiryRemote', newValues).then(() => {
			setState((state) => ({
				...state,
				isLoading: false,
				success: 1,
				error: null,
			}));

			resetForm();
			ref.current = true;

			setTimeout(() => {
				if (ref.current) {
					setState((state) => ({ ...state, success: 2 }));

					props.onSave && props.onSave();
					ref.current = false;
				}
			}, 15000);
		}).catch((message: string) => {
			setState((state) => ({
				...state,
				isLoading: false,
				error: message,
			}));

			timer.current = setTimeout(() => {
				setState((state) => ({ ...state, error: null }));
			}, 5000);
		});
	};

	const mainPageId = getMainPageId(mainPageMenu, props.pageId);

	const { success, isLoading } = state;

	const { t, doctor, controlsIdPrefix = '' } = props;
	const doctors = React.useMemo(() => {
		if (!doctor) return initDoctors.items;
		return doctor && initDoctors.items.find((item) => doctor?.id === item.id) ? initDoctors.items : [doctor];
	}, [doctor, initDoctors]);
	const locationId = props.locationId || (doctor?.locations?.[0]?.locationId ?? (offices.items.length ? offices.items[0].id : -1));

	const genderOptions = [
		{ value: Gender.Female, label: t('forms.Female') },
		{ value: Gender.Male, label: t('forms.Male') },
		{ value: Gender.Other, label: t('forms.Other') },
	];

	const selectProps = {
		theme: (theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary25: 'rgba(255, 255, 255, 0.3)',
				primary: 'rgba(238, 174, 21, 0.5)',
			},
		}),
		styles: {
			option: (base, state) => ({
				...base,
				color: state.isSelected ? '#fff !important' : 'inherit',
				backgroundColor: props.atModal
					? state.isSelected ? '#a89567' : 'inherit'
					: state.isSelected ? '#5a4a2f' : 'inherit',
			}),
		},
	};

	return (
		<div className={`contact-us-form ${props.className || ''}`} ref={container}>
			<Formik
				initialValues={{
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					locationId,
					mainPageId: mainPageId > 0
						? mainPageId
						: (mainPageMenu.length
							? mainPageMenu.filter((page) => {
								return !doctor || doctor.pages.some((doctorPage) => doctorPage.pageId === page.id);
							})?.[0]?.id
							: -1),
					pageId: -1,
					doctorId: doctor?.id || -1,
					text: '',
					date: null,
					time: null,
					gender: Gender.Female,
					timeInterval: null,
					path: '',
					device: Device.Desktop,
				}}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{(formikBag: FormikProps<FormValues>) => (
					<Form>
						<div className="row">
							<TranslatedFormikField
								fieldName="firstName"
								inputProps={{ placeholder: `${t('forms.firstName')}*` }}
								containerClassName={`form-group col-sm-${showDateOfBirth ? 3 : 4}`}
								render={({ form, field }: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) =>
									<input
										className="form-control"
										type="text"
										id={`${controlsIdPrefix}${field.name}`}
										{...field}
										{...inputProps}
										onChange={(e) => form.setFieldValue(field.name, e.currentTarget.value?.replace(/\d/g, ''))}
									/>}
							/>
							<TranslatedFormikField
								fieldName="lastName"
								inputProps={{ placeholder: `${t('forms.lastName')}*` }}
								containerClassName={`form-group col-sm-${showDateOfBirth ? 3 : 4}`}
								render={({ form, field }: FieldProps, inputProps?: React.HTMLProps<HTMLInputElement>) =>
									<input
										className="form-control"
										type="text"
										id={`${controlsIdPrefix}${field.name}`}
										{...field}
										{...inputProps}
										onChange={(e) => form.setFieldValue(field.name, e.currentTarget.value?.replace(/\d/g, ''))}
									/>}
							/>
							<TranslatedFormikField
								fieldName="gender"
								inputProps={{ placeholder: `${t('forms.Gender')}*` }}
								containerClassName={`form-group col-sm-${showDateOfBirth ? 3 : 4}`}
								render={({ field, form }: FieldProps) =>
									<Select
										atModal={props.atModal}
										value={field.value}
										options={genderOptions}
										placeholder={`${t('forms.Gender')}*`}
										onChange={(value: any) => {
											form.setFieldValue(field.name, +(value.value));
										}}
									/>
								}
							/>
							{showDateOfBirth
								? (
									<TranslatedFormikField
										containerClassName="col-sm-3 form-group"
										fieldName="birthDate"
										render={({ field }: FieldProps<number, FormValues>) =>
											<LazyDatePickerReact
												isMobile={isMobile}
												maxToday
												yearsSelectOptions={years}
												value={field.value}
												placeholder={t('forms.birthDate')}
												datePickerProps={{
													id: 'birthDate',
													showMonthDropdown: true,
													showYearDropdown: true,
													dropdownMode: 'select',
													autoComplete: 'off',
													maxDate: new Date(+new Date() - 18 * 365 * 24 * 1000 * 3600),
													selectsDisabledDaysInRange: true,
													icon: 'fa fa-calendar-o',
												}}
												selectProps={selectProps}
												onChange={(date) => formikBag.setFieldValue(field.name, date)}
											/>
										}
									/>) : null}
						</div>
						<div className="row">
							<TranslatedFormikField
								fieldName="email"
								inputProps={{
									inputMode: 'email',
									placeholder: `${t('forms.email')}*`,
									id: `${controlsIdPrefix}email`,
								} as any}
							/>
							<TranslatedFormikField
								fieldName="phone"
								inputProps={{ placeholder: `${t('forms.phone')}*` }}
								render={(fieldProps: FieldProps<FormValues>) =>
									<FormikPhoneControl
										id={`${controlsIdPrefix}phone`}
										placeholder={`${t('forms.phone')}*`}
										fieldProps={fieldProps}
									/>
								}
							/>
						</div>
						<div className="row">
							<TranslatedFormikField
								containerClassName="col-sm-4 form-group"
								fieldName="locationId"
								render={({ field, form }: FieldProps<number, FormValues>) =>
									<LocationSelect
										atModal={props.atModal}
										items={offices.items}
										form={form}
										field={field}
									/>
								}
							/>
							<TranslatedFormikField
								containerClassName="col-sm-4 form-group"
								fieldName="mainPageId"
								render={({ field, form }: FieldProps) =>
									<Select
										atModal={props.atModal}
										placeholder={t('forms.Cosmetic or Dermatology')}
										value={getValueForSelect(field.value)}
										onChange={(value: any) => {
											form.setFieldValue(field.name, +(value?.value || -1));
											form.setFieldValue('pageId', -1);
											const currentDoctor = doctors.find((doctor) => doctor.id === formikBag.values.doctorId);
											if (currentDoctor && !currentDoctor.pages.some((page) => page.pageId === +(value || -1))) {
												form.setFieldValue('doctorId', -1);
											}
										}}
										options={mainPageMenu.map((page) => ({
											value: page.id,
											label: getPageShortName(page, language),
										}))}
										aria-label={t('forms.Cosmetic or Dermatology')}
									/>
								}
							/>
							<Field
								name="pageId"
							>
								{(fieldProps: FieldProps<FormValues>) => {
									let subpages: Array<Page> = [];

									const mainId = +(formikBag.values.mainPageId as number);

									if (mainId > 0) {
										const parent = menu.items.find((page) => page.id === mainId);

										if (parent && parent.children) {
											subpages = parent.children.list;
										}
									}

									return <FormikInput
										fieldProps={fieldProps}
										containerClassName="form-group col-sm-4"
										ErrorComponent={TranslatedErrorMessage}
										render={({ field, form }: FieldProps) =>
											<Select
												atModal={props.atModal}
												value={getValueForSelect(field.value)}
												placeholder={t('forms.Procedure of interest')}
												onChange={(value: any) => form.setFieldValue(field.name, +(value?.value || -1))}
												aria-label={t('forms.Procedure of interest')}
												options={subpages.map((page) => ({
													value: page.id,
													label: getPageShortName(page, language),
												}))}
											/>
										}
									/>;
								}}
							</Field>
						</div>
						<div className="row">
							<TranslatedFormikField
								fieldName="doctorId"
								containerClassName="form-group col-sm-6"
								render={({ field, form }: FieldProps<number, FormValues>) =>
									<DoctorSelect
										atModal={props.atModal}
										items={doctors.filter((q) => q.locations
											.some((location) => location.locationId === formikBag.values.locationId)
											&& q.pages.some((page) => page.pageId === formikBag.values.mainPageId))}
										language={language}
										form={form}
										field={field}
									/>
								}
							/>
							<TranslatedFormikField
								containerClassName="col-sm-6 form-group"
								fieldName="date"
								render={({ field }: FieldProps<number, FormValues>) =>
									<LazyDatePickerReact
										isMobile={isMobile}
										yearsSelectOptions={years}
										value={field.value}
										placeholder={t('forms.Date')}
										datePickerProps={{
											showMonthDropdown: true,
											showYearDropdown: true,
											dropdownMode: 'select',
											autoComplete: 'off',
											minDate: new Date(),
											selectsDisabledDaysInRange: true,
											icon: 'fa fa-calendar-o',
										}}
										selectProps={selectProps}
										onChange={(date) => formikBag.setFieldValue(field.name, date)}
										utc
									/>
								}
							/>
						</div>
						<div>
							<TranslatedFormikField
								fieldName="text"
								containerClassName=" "
								inputProps={{ placeholder: t('forms.Additional Comments') }}
								render={({ field, form }: FieldProps) =>
									<textarea
										aria-label={t('forms.Additional Comments')}
										className="form-control"
										id={`${controlsIdPrefix}${field.name}`}
										{...field}
										style={{ marginBottom: 3 }}
										value={field.value === null ? '' : field.value}
										onChange={(e) => form.setFieldValue(field.name, e.currentTarget.value?.slice(0, 200))}
									/>}
							/>
							{formikBag.values.text.length}
							{' '}
							/ 200
							{' '}
							{t('forms.characters')}
							.
						</div>
						<div className="text-center" style={{ marginTop: -5 }}>
							<Button className="general-form-button" isLoading={isLoading}>{t('forms.submit')}</Button>
						</div>
						<div className={`contact-us-form__message ${success === 1 ? 'animated zoomIn' : (success === 2 ? 'animated zoomOut' : '')}`}>
							<div className="contact-us-form__message-inner">
								{t('forms.requestSuccess')}
								<button
									type="button"
									className="general-form-button btn btn-default"
									onClick={() => {
										ref.current = false;
										setState((prev) => ({ ...prev, success: 2 }));
										props.onSave && props.onSave();
									}}
								>
									OK
								</button>
							</div>
						</div>
						{state.error && <div className="contact-us-form__message animated animated__short zoomIn">
							<div className="contact-us-form__message-inner alert alert-danger">
								Oops!!!
								<br />
								{' '}
								{t('Something went wrong')}
								<button
									type="button"
									className="general-form-button btn btn-default"
									onClick={() => {
										setState((prev) => ({ ...prev, error: null }));
										if (timer.current) {
											clearTimeout(timer.current);
											timer.current = null;
										}
									}}
								>
									{t('Close')}
								</button>
							</div>
						</div>}
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default withTranslation()(ContactUsForm);
