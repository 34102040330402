import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ImageWithSkeleton from '@app/components/UI/ImageWithSkeleton/ImageWithSkeleton';
import { Special } from '@app/objects/Special';
import { capitalizeFirstLetter, getMoneyString } from '@app/components/Utils';

import noImage from '@images/no-image.jpg';

const SpecialCard: React.FC<{item: Special, openModal}> = ({ item, openModal }) => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const { t, i18n } = useTranslation();
	const lang = capitalizeFirstLetter(i18n.language);

	React.useEffect(() => {
		setLoading(false);
	}, []);
	const inner = (<>
		<div className="special-card-head">
			<ImageWithSkeleton
				withLazy
				src={item.originalAvatar ? `/remote/${item.originalAvatar}` : noImage}
				alt={item.name}
				onLoad={() => setLoading(false)}
				hidden={loading}
			/>
			<div className="special-modal-page">
				<div className="special-modal-page__preview" onClick={() => openModal(item)}>{t('site.Fast Preview')}</div>
			</div>
		</div>
		<div className="special-card-body">
			<div className="product-desc">
				<span className="product-title" onClick={() => openModal(item)}>
					<div className="product-title__name" title={item.name}>
						{item.name}
					</div>
				</span>
				{item.location && <div className="map-point" title={item.location[`name${lang}`]}>
					<span className="inline-tag">
						<i className="fa fa-map-marker" />
						{item.location[`name${lang}`]}
					</span>
				</div>}
				<div className="product-caption" title={item.description}>
					{item.description}
				</div>
				<div className="special-price__container">
					<span className="special-price mr10">{getMoneyString(item.price)}</span>
					<span className="special-original-price">{getMoneyString(item.originalPrice)}</span>
				</div>
				<div className="product-desc__btn-wrapper">
					<a
						target="_blank"
						href={`https://www.northerntuna.com/specials/${item.path}`}
						className="btn btn-default"
						rel="noreferrer"
					>
						<span className="add-special-btn">{t('site.Buy')}</span>
					</a>
				</div>
			</div>
		</div>
	</>);

	return <>
		<div className="special-card__link special-card">{inner}</div>
	</>;
};

export default SpecialCard;
