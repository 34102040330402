import { NavigateFunction } from 'react-router-dom';

import { parseQuery } from '@common/typescript/utils/url';
import { BaseParams } from '@common/react/objects/BaseParams';

export const urlHandleItems = (searchObj: object, key: string, step: number = 0, prefix?: string) => {
	let res: string = '';

	if (key === `${prefix || ''}column` && Array.isArray(searchObj[key])) {
		res = `${key}=[${searchObj[key].map((item) => `[${item ? Object.keys(item).map((col) => item[col]) : ''}]`)}]`;
	} else {
		res = Array.isArray(searchObj[key]) ? `${key}=[${searchObj[key]}]` : `${key}=${searchObj[key]}`;
	}

	return res;
};

export const handleUrl = (searchParams: object, location, navigate: NavigateFunction, page?: number, prefix?: string, pageInSearch?: boolean) => {
	const searchObj = { ...parseQuery(location.search), ...searchParams };

	const emptyValues = ['', 'undefined', '-1', undefined, -1];

	const search = Object.keys(searchObj)
		.filter((k) => emptyValues.indexOf(searchObj[k]) === -1)
		.map((k) => urlHandleItems(searchObj, k, 0, prefix)).join('&');

	navigate({
		...location,
		search,
		pathname: `${pageInSearch ? location.pathname : location.pathname.replace(/\/-?\d+/, `/${page || 1}`)}`,
	}, { replace: true });
};

export const getSearchParamsFromUrl = (location, prefix?: string) => {
	const searchObj: BaseParams = parseQuery(location.search);

	Object.keys(searchObj).forEach((key) => {
		const value: string = searchObj[key];

		if (value === '') {
			searchObj[key] = undefined;
			return;
		}

		if (key === `${prefix || ''}column`) {
			const matches = value.slice(1, value.length - 1).split(/[[\]]{1,2}/).filter((q) => q.length > 3);

			if (matches && matches.length) {
				const result: Array<any> = [];

				matches.map((match) => {
					const values = match.split(',');

					if (values && values.length === 2) {
						result.push({
							caption: values[0],
							direction: values[1],
						});
					}
				});

				searchObj[key] = result;
			}

			return;
		}

		if (value === 'true') {
			searchObj[key] = true;
		} else if (value === 'false') {
			searchObj[key] = false;
		} else if (value === 'null') {
			searchObj[key] = null;
		} else if (value[0] === '[' && value[value.length - 1] === ']') {
			if (value === '[]') {
				searchObj[key] = [];
			} else {
				searchObj[key] = value.slice(1, value.length - 1).split(',').map((v) => ((+v || +v === 0) ? +v : v));
			}
		} else if (+value || +value === 0) {
			searchObj[key] = +value;
		}
	});

	return searchObj;
};
