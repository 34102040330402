import * as React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

import Layout from '@app/components/Layout';
import InitI18n from '@app/components/InitLang';
import { MainRoute } from '@app/components/Routes/MainRoute';

const paramsTranslated = {
	fallback: <Loader />,
};

const base = '/:locale(en|es)?';

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */
		'@app/components/Pages/Home/Home'
	)), paramsTranslated);
const Contacts = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ContactsPage" */
		'@app/components/Pages/Contacts/Contacts'
	)), paramsTranslated);
const Section = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SectionPage" */
		'@app/components/Pages/Section/Section'
	)), paramsTranslated);
const DoctorList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorListPage" */
		'@app/components/Pages/DoctorList/DoctorList'
	)), paramsTranslated);
const BeforeAfter = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BeforeAfterPage" */
		'@app/components/Pages/BeforeAfterList/BeforeAfter'
	)), paramsTranslated);
const DoctorPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorPage" */
		'@app/components/Pages/DoctorList/DoctorPage'
	)), paramsTranslated);
const ClinicPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicPage" */
		'@app/components/Pages/Clinic/ClinicPage'
	)), paramsTranslated);
const BlogPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BlogPage" */
		'@app/components/Pages/Blog/Blog'
	)), paramsTranslated);
const FaqListPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaqListPage" */
		'@app/components/Pages/Faq/Faq'
	)), paramsTranslated);
const SingleFaq = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SingleFaqPage" */
		'@app/components/Pages/Faq/FaqPage'
	)), paramsTranslated);
const Specials = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specials" */
		'@app/components/Pages/Specials/Specials'
	)), paramsTranslated);
const Sale = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Sale" */
		'@app/components/Pages/Sale/Sale'
	)), paramsTranslated);

export const baseRoutes = (
	<>
		<Route
			path="/"
			element={<MainRoute />}
		>
			<Route path={base} element={<Home />} />
			<Route path={`${base}/home`} element={<Home />} />
			<Route path={`${base}/contacts`} element={<Contacts />} />
			<Route path={`${base}/contactos`} element={<Contacts />} />
			<Route path={`${base}/specials/:page?`} element={<Specials />} />
			<Route path={`${base}/doctors`} element={<DoctorList />} />
			<Route path={`${base}/practica/doctors`} element={<DoctorList />} />
			<Route path={`${base}/practice/doctors`} element={<DoctorList />} />
			<Route path={`${base}/beforeafter`} element={<BeforeAfter />} />
			<Route path={`${base}/doctor/:path?`} element={<DoctorPage />} />
			<Route path={`${base}/clinic/:path?`} element={<ClinicPage />} />
			<Route path={`${base}/faq`} element={<FaqListPage />} />
			<Route path={`${base}/faq/:page`} element={<SingleFaq />} />
			<Route path={`${base}/blog`} element={<BlogPage />} />
			<Route path={`${base}/sale/:path`} element={<Sale />} />
			<Route path={`${base}/:path/*`} element={<Section />} />
		</Route>
		<Route path="*" element={<NotFoundRoute openRoute={MainRoute} />} />
	</>
);

export const clientRoutes = (<Route
	element={<InitI18n>
		<Layout>
			<Outlet />
		</Layout>
	</InitI18n>}
>
	{baseRoutes}
</Route>);

export const routes = (<InitI18n>
	<Layout>
		<Routes>
			{baseRoutes}
		</Routes>
	</Layout>
</InitI18n>);
