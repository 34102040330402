import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { imageSize } from '@common/react/utils/utils';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';
import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';

import { Doctor } from '@commonTuna/react/objects/BaseDoctor';
import { DoctorProfession } from '@commonTuna/react/objects/DoctorProfession';
import { DoctorLocation } from '@commonTuna/react/objects/DoctorLocation';

import { capitalizeFirstLetter, isEmptyOrSpaces } from '@app/components/Utils';
import { ApplicationState } from '@app/store';
import ImageWithSkeleton from '@app/components/UI/ImageWithSkeleton/ImageWithSkeleton';
import LocationName from '@app/components/UI/LocationName/LocationName';

import '@app/scss/components/doctorCard.scss';

interface Props {
	item: Doctor;
	className?: string;
}

export const IsInvalidDoctorCard = (doctor: Doctor, langString: string) => {
	return isEmptyOrSpaces(doctor[`name${langString}`]);
};

const DoctorCard: React.FC<Props> = ({ item, className = 'col-sm-4' }) => {
	const { i18n } = useTranslation();
	const langString: 'En' | 'Es' = capitalizeFirstLetter(i18n.language);
	const offices = useSelector((state: ApplicationState) => state.offices.items, shallowEqual);

	if (IsInvalidDoctorCard(item, langString)) {
		return null;
	}

	return <div className={className}>
		<div className="doctor-card">
			{item[`path${langString}`] ? <LinkWithLang className="doctor-link" to={`/doctor/${item[`path${langString}`]}`} /> : null}
			<div className="square-block">
				<div className="doctor-card__image">
					<ImageLazy src={item.originalAvatar} alt={item[`name${langString}`]} />
					{item.certificates?.length > 0
						&& <div className="doctor-certificates">
							{item.certificates
								.filter((item) => item.certificate.image)
								.map((item, i) =>
									<div key={item.id} className="certificate-wrapper">
										<div>
											<ImageWithSkeleton
												alt={item.certificate.name}
												key={item.id}
												src={imageSize(item.certificate.image, '_thumb')}
											/>
										</div>
									</div>)}
						</div>
					}
				</div>
			</div>
			<div className="doctor-card__name">
				{item[`path${langString}`] ? <LinkWithLang to={`/doctor/${item[`path${langString}`]}`}>
					{item[`name${langString}`]}
				</LinkWithLang> : item[`name${langString}`]}
			</div>
			<div className="doctor-card__text">
				<div className="clearfix doctor-card__locations">
					{item.professions?.length > 0 ? <div className="doctor-card__professions pull-left">
						{item.professions && item.professions.map((profession: DoctorProfession, index: number) => (
							<span key={profession.id}>
								{profession.profession[`name${langString}`] + (index !== item.professions.length - 1 ? ', ' : '')}
							</span>
						))}
					</div> : null}
					{item.locations?.length > 0
						? item.locations && item.locations.map((location: DoctorLocation, index: number) =>
							<div className="inline-tag pull-right" key={`location-${location.id}`}>
								<LocationName item={offices.find((office) => office.id === location.location.id) || location.location} />
							</div>)
						: null}
				</div>
				{item[`description${langString}`]
					? <div className="doctor-card__description" dangerouslySetInnerHTML={{ __html: item[`description${langString}`] }} />
					: null}
			</div>
		</div>
	</div>;
};

export default React.memo(DoctorCard);
