import * as React from 'react';
import { useLocation, Location } from 'react-router-dom';

import '@common/react/scss/components/error.scss';

interface Props {
	location: Location;
}

export class ErrorBoundary extends React.Component<Props, {hasError: boolean}> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{ hasError: boolean }>, snapshot?: any) {
		if (prevProps.location !== this.props.location && this.state.hasError) {
			this.setState({ hasError: false });
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return <div className="error-component">
				<div className="error-component__content">
					<i className="fa fa-exclamation-triangle" aria-hidden="true" />
					<br />
					Oops!!!
					{' '}
					<br />
					{' '}
					Something went wrong
				</div>
			</div>;
		}

		return this.props.children;
	}
}

export const ErrorBoundaryWithLocation: React.FC = ({ children }) => {
	const location = useLocation();
	return <ErrorBoundary
		location={location}
	>
		{children}
	</ErrorBoundary>;
};

export default ErrorBoundary;
