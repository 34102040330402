import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Region } from '@commonTuna/react/objects/Region';

interface Props {
	areas: Array<Region>;
	className?: string;
}

export const BottomAreas: React.FC<Props> = ({ areas, className }) => {
	const { t } = useTranslation();

	return <div className={`bottom-areas ${className}`}>
		<div className="container">
			<h4>{t('site.Service Areas')}</h4>
			<ul className="bottom-areas__list">
				{areas.map((item, index) => <li key={index}>
					{item.name}
				</li>)}
			</ul>
		</div>
	</div>;
};
