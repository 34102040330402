import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { ErrorBoundaryWithLocation } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';

import { MainLayout } from '@app/components/Layouts/MainLayout';

interface Props {
	component?: any;
}

export const MainRoute: React.FC<Props> = ({ component: Component }) => (
	<MainLayout>
		<ErrorBoundaryWithLocation>
			{Component ? <Component /> : <Outlet />}
		</ErrorBoundaryWithLocation>
	</MainLayout>
);
