import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import NotFoundComponent from '@common/react/components/Core/NotFoundPageProvider/NotFoundComponent';
import PageNotFound from '@common/react/components/UI/PageNotFound/PageNotFound';

import { BaseApplicationState } from '@common/react/store';
import { BaseUser } from '@common/typescript/objects/BaseUser';

interface Props {
	openRoute: any;
	loginRoute?: any;
	component?: any;
	title?: string;
	notFoundPage?: React.ReactNode;
	loginRender?: React.ReactNode;
	openRender?: React.ReactNode;
	getServerPage?: (state) => any;
}

const defaultGetServerPage = (state) => state?.serverPage;

const NotFoundRoute: React.FC<Props> = ({
	title = '404 Not Found', notFoundPage, openRoute, loginRoute = openRoute, ...rest
}) => {
	const { component: Component = () => <NotFoundComponent defaultNotFoundComponent={<PageNotFound />} /> } = rest;
	const { loginRender = Component, openRender = Component, getServerPage = defaultGetServerPage } = rest;
	const user = useSelector((state: BaseApplicationState<BaseUser>) => state?.login?.user, shallowEqual);
	const serverPage = useSelector(getServerPage, shallowEqual);

	React.useEffect(() => {
		if (serverPage?.page) {
			serverPage.page = null;
		}
	}, []);

	const Route: any = user ? loginRoute : openRoute;
	const Node = user ? loginRender : openRender;

	return <>
		{title && <Helmet>
			<title>{title}</title>
		</Helmet>}
		<Route
			component={Node}
		/>
	</>;
};

export default NotFoundRoute;
