export const getMinutesFromDuration = (duration: string): number => {
	const [h, m] = duration.split(':');

	return (+h * 60) + +m;
};

export const timeFormat = (time: string) => {
	const [h, m] = time.split(':');
	let a = false;
	if (+h < 12) {
		a = true;
	}
	return `${a ? h : +h - 12}:${m} ${a ? 'a' : 'p'}m`;
};

export const transformToUtc = (date: number): number => {
	const d = new Date(date);
	return +new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0));
};

export const isSameDate = (date1: Date, date2: Date) => {
	return date1.getFullYear() === date2.getFullYear()
	&& date1.getMonth() === date2.getMonth()
	&& date1.getDate() === date2.getDate();
};

export const showDate = (date: number, showHour?: boolean, utc?: boolean, showSec?: boolean, showAmPm: boolean = true) => {
	const solvedDate = new Date(date);
	const month = solvedDate[`get${utc ? 'UTC' : ''}Month`]() + 1;
	const day = solvedDate[`get${utc ? 'UTC' : ''}Date`]();
	const hours = solvedDate[`get${utc ? 'UTC' : ''}Hours`]();
	const minutes = solvedDate[`get${utc ? 'UTC' : ''}Minutes`]();
	const sec = solvedDate.getSeconds();

	const amPm = hours < 12 || hours === 24 || (hours === 0 && minutes === 0) ? 'AM' : 'PM';
	return `${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}-${solvedDate[`get${utc ? 'UTC' : ''}FullYear`]()}${
		showHour
			? ` ${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? '0' : ''}${minutes}${
				showSec ? `:${sec < 10 ? '0' : ''}${sec}` : ''
			} ${showAmPm ? amPm : ''}`
			: ''
	}`;
};

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const fromNow = (date) => {
	const seconds = Math.floor((+new Date() - date) / 1000);
	const years = Math.floor(seconds / 31536000);
	const months = Math.floor(seconds / 2592000);
	const days = Math.floor(seconds / 86400);

	if (days > 548) {
		return `${years} years ago`;
	}
	if (days >= 320 && days <= 547) {
		return 'a year ago';
	}
	if (days >= 45 && days <= 319) {
		return `${months} months ago`;
	}
	if (days >= 26 && days <= 45) {
		return 'a month ago';
	}

	const hours = Math.floor(seconds / 3600);

	if (hours >= 36 && days <= 25) {
		return `${days} days ago`;
	}
	if (hours >= 22 && hours <= 35) {
		return 'a day ago';
	}

	const minutes = Math.floor(seconds / 60);

	if (minutes >= 90 && hours <= 21) {
		return `${hours} hours ago`;
	}
	if (minutes >= 45 && minutes <= 89) {
		return 'an hour ago';
	}
	if (seconds >= 90 && minutes <= 44) {
		return `${minutes} minutes ago`;
	}
	if (seconds >= 45 && seconds <= 89) {
		return 'a minute ago';
	}
	if (seconds >= 0 && seconds <= 45) {
		return 'a few seconds ago';
	}
};
