import {
	createStore,
	applyMiddleware,
	compose,
	combineReducers,
	Store,
	StoreEnhancerStoreCreator,
	ReducersMapObject,
} from 'redux';
import thunk from 'redux-thunk';
import { History } from 'history';

import { TypeKeys as LoginKeys } from '@common/react/store/Login';

import { BaseApplicationState } from '@common/react/store';

import { BaseUser } from '@common/react/objects/BaseUser';

let store: any = null;

const configureStore = <
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(history: History, reducers: ReducersMapObject, initialState?: TApplicationState, saveAfterClearReducerNames?: Array<string>) => {
	// Build middleware. These are functions that can process the actions before they reach the store.
	const windowIfDefined = typeof window === 'undefined' ? null : window as any;
	// If devTools is installed, connect to it
	const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__;
	const createStoreWithMiddleware = (compose(
		applyMiddleware(thunk),
		devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next,
	) as any)(createStore);

	// Combine all reducers and instantiate the app-wide store instance
	const allReducers = buildRootReducer(reducers, saveAfterClearReducerNames);

	if (typeof createStoreWithMiddleware === 'function') {
		store = createStoreWithMiddleware(allReducers, initialState) as Store<TApplicationState>;
	}

	return store;
};

const buildRootReducer = <TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>(
	allReducers: ReducersMapObject,
	saveAfterClearReducerNames: Array<string> = ['buildData', 'hostOptions'],
) => {
	return (state, action) => {
		if (action.type === LoginKeys.CLEARSTATE) {
			const newState = {} as TApplicationState;
			saveAfterClearReducerNames.forEach((name) => {
				newState[name] = state[name];
			});
			return combineReducers<TApplicationState>(allReducers)(newState, action);
		}

		return combineReducers<TApplicationState>(allReducers)(state, action);
	};
};

export const updateReducers = (reducers) => {
	if (store === null) {
		throw 'configureStore must be called first!';
	}

	store.replaceReducer(buildRootReducer(reducers));
};

export default configureStore;
