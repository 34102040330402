import React from 'react';
import { useTranslation } from 'react-i18next';

import { Lang } from '@common/typescript/objects/Lang';
import { getBackgroundUrl } from '@common/typescript/Utils';
import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';

import { getPageShortName, capitalizeFirstLetter } from '@app/components/Utils';
import '@app/scss/components/serviceCard.scss';

const ServiceCard: React.FC<{page}> = ({ page }) => {
	const { i18n } = useTranslation();
	const ref = React.useRef<HTMLDivElement | null>(null);
	const [visible, setVisible] = React.useState(false);
	const langName: 'En' | 'Es' = capitalizeFirstLetter(i18n.language);

	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setVisible(true);
				}
			}, {
				rootMargin: '20px',
				threshold: 0.01,
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, [ref.current]);
	const name = getPageShortName(page, Lang[langName]);

	const fontSize = 35 - ((4 * (name.length || 1)) / 3);

	return <div className="col-lg-3 col-sm-4" ref={ref}>
		<div className="sub-page">
			<LinkWithLang className="sub-page__link" to={`/${langName === 'En' ? page.fullPath : page.fullPathEs}`} />
			{page.avatar
				&& <div
					className="sub-page__image"
					style={{ backgroundImage: visible ? getBackgroundUrl(page.avatar) : undefined }}
				/>}
			<h2
				className="sub-page__name"
				style={{ fontSize: fontSize < 16 ? 16 : fontSize > 20 ? 20 : fontSize }}
			>
				{name}
			</h2>
		</div>
	</div>;
};

export default ServiceCard;
